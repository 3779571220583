
import { ClientObject } from '../_models/client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ccworksheetsObject } from '../_models/ccworksheets';
import { GenericService } from './generic.service';



@Injectable()
export class ProductsService {

  private _Url: string = "/api/products/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "products";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService,
    private genericService: GenericService,) {

  }

  /*PRODUCTS CATEGORIES*/
  getAllProductsCategories(categoryType: string) {
    return this.authHttp.get<any>(this._Url + "GetAllProductsCategories/ALL/" + categoryType + "/");
  }


  /*PRODUCTS*/
  getEveryProduct() {
    return this.authHttp.get<any>(this._Url + "GetAllProducts/EVR/" + 0 + "/");
  }

  getAllProducts(productCategoryID: number) {
    return this.authHttp.get<any>(this._Url + "GetAllProducts/ALL/" + productCategoryID + "/");
  }

  getProductByID(productID: number) {
    return this.authHttp.get<any>(this._Url + "GetProductByID/ID/" + productID + "/");
  }

  getAllProductsByType(productCategoryID: number, productType: string) {
    return this.authHttp.get<any>(this._Url + "GetAllProductsByType/TYP/" + productCategoryID + "/" + productType + "/");
  }
  getProductByTypeAndMajor(productMajorCategory: number, productType: string) {
    return this.authHttp.get<any>(this._Url + "GetProductByTypeAndMajor/TMJ/" + productMajorCategory + "/" + productType + "/");
  }
  checkProductExistingCategories(productCategoryID: number) {
    return this.authHttp.get<any>(this._Url + "CheckProductExistingCategories/CT/" + productCategoryID + "/");
  }
  /*PRODUCTS FORMS*/
  getAllProductsForms() {
    return this.authHttp.get<any>(this._Url + "GetAllProductsForms/ALL/");
  }

  getAllProductsFormsList(viewAllAgentsProducts,filterProductType, filterProductStatus, filterCoverNotesProductStatus) {

    if (filterProductType == '' || filterProductType == undefined) {
      filterProductType = '~';
    }

    if (filterProductStatus == '' || filterProductStatus == undefined) {
      filterProductStatus = '~';
    }

    if (filterCoverNotesProductStatus == '' || filterCoverNotesProductStatus == undefined) {
      filterCoverNotesProductStatus = '~';
    }
    
    return this.authHttp.get<any>(this._Url + "GetAllProductsFormsList/" + (viewAllAgentsProducts ?/*AAG*/'ALL':'ALL')+"/"+ filterProductType + "/" + filterProductStatus + "/" + filterCoverNotesProductStatus + "/" );
  }

  getProductsFormsByID(productFormID: number) {
    return this.authHttp.get<any>(this._Url + "GetProductsFormsByID/ID/" + productFormID + "/");
  }

  sendProductsFormsEmailToBranch(productFormID: number, sendEmailObject: object) {
    return this.authHttp.post<any>(this._Url + "SendProductsFormsEmailToBranch/" + productFormID + "/", sendEmailObject);
  }

  //public sendReportEmail(sendEmailObject: object) {
  //  return this.authHttp.post<any>(this._Url + "SendReportEmail/", sendEmailObject);
  //}
  addCoverNote(model: Object) {
    return this.authHttp.post<any>(this._Url + "ModifyCoverNotes" + "/I/", model);
  }
  addPostedCnEditApproval(productFormID: number, editedObject: Object) {
    return this.authHttp.post<any>(this._Url + "AddPostedCnEditApproval/" + productFormID+"/", editedObject)
  }
  addProductsForm(model: Object) {
    return this.authHttp.post<any>(this._Url + "ModifyProductsForm" + "/I/", model);
  }


  updateProductsForm(productFormID: number, model: Object) {
    return this.authHttp.post<any>(this._Url + "ModifyProductsForm" + "/U/" + productFormID + "/", model);
  }
  sentCoverNoteApprovalEmail(productFormID: number,resend:boolean) {
    return this.authHttp.get<any>(this._Url + "SendCoverNoteApprovalEmail" + "/" + productFormID + "/"+resend);
  }
  updateProductsFormWithoutStatus(productFormID: number, model: Object) {
    return this.authHttp.post<any>(this._Url + "ModifyProductsForm" + "/UWS/" + productFormID + "/", model);
  }

  deleteProductsForm(productFormID: number, model: Object) {
    return this.authHttp.post<any>(this._Url + "ModifyProductsForm" + "/D/" + productFormID + "/", model);
  }

  finalizeProductsForm(runCode:string,productFormID: number, model: Object) {

    return this.authHttp.post<any>(this._Url + "PostProductsForm" + "/"+runCode+"/" + productFormID + "/", model);
  }

  serviceStartProductsForm(productFormID: number, model: Object) {

    return this.authHttp.post<any>(this._Url + "ModifyProductsForm" + "/SER/" + productFormID + "/", model);
  }

  importProduct(model: Object, SyReferenceNumber: string, productFormID: number) {
    return this.authHttp.post<any>(this._Url + "ImportProduct/" + SyReferenceNumber + "/" + productFormID, model);
  }

  importProductDriver(model: Object) {
    return this.authHttp.post<any>(this._Url + "ImportProductDriver/", model);
  }

  updateProductsFormUR(productFormID: number, ReferenceNumber: string) {
    return this.authHttp.post<any>(this._Url + "ModifyProductsFormUR" + "/UR/" + productFormID + "/", ReferenceNumber);
  }

  syInsertSepa(model: Object) {
    return this.authHttp.post<any>(this._Url + "SyInsertSepa" + "/", model);
  }

  completedSepaByAgent(productFormID: number) {
    return this.authHttp.get<any>(this._Url + "CompletedSepaByAgent/" + productFormID + "/");
  }

  quotation_GetPackageCost(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_GetPackageCost/", model);
  }

  quotation_GetCubicCapacity(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_GetCubicCapacity/", model);
  }

  quotation_CalculateOwnDamage(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_CalculateOwnDamage/", model);
  }

  quotation_CalculateLoadingPremium(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_CalculateLoadingPremium/", model);
  }

  quotation_CalculateShortTerm(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_CalculateShortTerm/", model);
  }

  quotation_GetCovers(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_GetCovers/", model);
  }

  quotation_GetPrices(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_GetPrices/", model);
  }

  quotation_GetLoadings(model: Object) {
    return this.authHttp.post<any>(this._Url + "Quotation_GetLoadings/", model);
  }

  // NEED THIS FOR ACCEPTANCE PAGE, CUSTOMIZE IT WITH YOUR FORM FIELDS

  //build tables

  buildDynamicHTML(productObject) {


    var returnHTML = '';

    //initialize HTML
    productObject.forEach(item => {

      //check what part is it


      //check entry whtat type is it and assign it
      if (item['Tag'] == 'Start') {
        returnHTML += this.startBuilderForm(item);
      }
      else if (item['Tag'] == 'Row') {
        //row
        returnHTML += this.rowBuilderForm(item);
      }
      else if (item['Tag'] == 'End') {
        //end
        returnHTML += this.endBuilderForm(item);
      }
      else if (item['Tag'] == 'Table') {
        //end
        returnHTML += this.endBuilderForm(item);
      }

    });

    return returnHTML;
  }


  tableBuilderForm(item) {

    var startHTML = '';

    if (item['Type'] == 'Section') {
      startHTML = this.startBuilderType_Section(item);
    }
    else if (item['Type'] == 'HTMLElement') {
      startHTML = this.startBuilderType_HTMLElement(item);
    }
    else if (item['Type'] == 'HTMLText') {

    }
    else if (item['Type'] == 'HTMLTable') {
      startHTML = this.startBuilderType_HTMLTable(item); // create a
    }
    return startHTML;

  }


  //START ----------------------------------------------------------------------------
  startBuilderForm(item) {

    var startHTML = '';

    if (item['Type'] == 'Section') {
      startHTML = this.startBuilderType_Section(item);
    }
    else if (item['Type'] == 'HTMLElement') {
      startHTML = this.startBuilderType_HTMLElement(item);
    }
    else if (item['Type'] == 'HTMLText') {
      startHTML = this.startBuilderType_HTMLText(item);
    }
    else if (item['Type'] == 'HTMLTextDouble') {
      startHTML = this.startBuilderType_HTMLTextDouble(item);
    }
    else if (item['Type'] == 'HTMLTable') {
      startHTML = this.startBuilderType_HTMLTable(item); // create a
    }
    return startHTML;

  }


  startBuilderType_Section(item) {

    var dynamicHTML = '';
    if (item["Display"] != "NO") {
      //check bootstrap class
      var bootstrapClass = '';
      if (item['BootstrapClass'] == undefined || item['BootstrapClass'] == '') {
        bootstrapClass = 'col-md-12 px-3';
      }
      else {
        bootstrapClass = item['BootstrapClass'];
      }

      var style = '';
      if (item['Style'] != undefined && item['Style'] != '') {
        style = item['Style'];
      }



      //if (item['PageBreak'] != undefined && item['PageBreak'] != '') {
      //  dynamicHTML += '<div class=""></div>';
      //}

      dynamicHTML += '<div class="' + bootstrapClass + '" style="' + style + '">';
      dynamicHTML += '<div><h5><u>' + item['Title'] + '</u></h3></div>';


      if (item['Rows'] != undefined) {

        dynamicHTML += '<table class="custom-table" style="table-layout: fixed;">';
        dynamicHTML += '<tr class="custom-tr">';
        dynamicHTML += '<th style="width:50%"></th>';
        for (let index = 0; index < item['Rows']; index++) {
          dynamicHTML += '<th></th>';
        }
        dynamicHTML += '</tr>';
      }
      else {
        dynamicHTML += `
  <table class="custom-table" style="table-layout: fixed;">

          <tr class="custom-tr">
            <th style="width:50%"></th>
            <th></th>
          </tr>
`;
      }
    }


    return dynamicHTML;

  }


  //HTMLText
  startBuilderType_HTMLText(item) {

    var dynamicHTML = '';

    //check bootstrap class
    var bootstrapClass = '';
    if (item['BootstrapClass'] == undefined || item['BootstrapClass'] == '') {
      bootstrapClass = 'col-md-12 px-3';
    }
    else {
      bootstrapClass = item['BootstrapClass'];
    }

    var style = '';
    if (item['Style'] != undefined && item['Style'] != '') {
      style = item['Style'];
    }



    //if (item['PageBreak'] != undefined && item['PageBreak'] != '') {
    //  dynamicHTML += '<div class=""></div>';
    //}

    dynamicHTML += '<div class="' + bootstrapClass + '" style="' + style + '">';
    dynamicHTML += '<span>' + item['Title'] + '</span';


    if (item['Rows'] != undefined) {

      dynamicHTML += '<table class="custom-table">';
      dynamicHTML += '<tr class="custom-tr">';
      dynamicHTML += '<th style="width:50%"></th>';
      for (let index = 0; index < item['Rows']; index++) {
        dynamicHTML += '<th></th>';
      }
      dynamicHTML += '</tr>';
    }
    else {
      dynamicHTML += `
  <table class="custom-table">

          <tr class="custom-tr">
            <th style="width:50%"></th>
            <th></th>
          </tr>
`;
    }



    return dynamicHTML;

  }

  //HTMLTextDouble
  startBuilderType_HTMLTextDouble(item) {

    var dynamicHTML = '';

    //check bootstrap class
    var bootstrapClass = '';
    if (item['BootstrapClass'] == undefined || item['BootstrapClass'] == '') {
      bootstrapClass = 'col-md-12 px-3';
    }
    else {
      bootstrapClass = item['BootstrapClass'];
    }

    var style = '';
    if (item['Style'] != undefined && item['Style'] != '') {
      style = item['Style'];
    }



    //if (item['PageBreak'] != undefined && item['PageBreak'] != '') {
    //  dynamicHTML += '<div class=""></div>';
    //}

    dynamicHTML += '<div class="' + bootstrapClass + '" style="' + style + '">';
    dynamicHTML += '<span>' + item['Title'] + '</span';


    if (item['Rows'] != undefined) {

      dynamicHTML += '<table class="custom-table">';
      dynamicHTML += '<tr class="custom-tr">';
      dynamicHTML += '<th style="width:50%"></th>';
      for (let index = 0; index < item['Rows']; index++) {
        dynamicHTML += '<th></th>';
      }
      dynamicHTML += '</tr>';
    }
    else {
      dynamicHTML += `
  <table class="custom-table">

          <tr class="custom-tr">
            <th style="width:50%"></th>
            <th></th>
          </tr>
`;
    }



    return dynamicHTML;

  }


  // Table Row
  startBuilderType_HTMLTable(item) {

    var dynamicHTML = '';

    //check bootstrap class
    var bootstrapClass = '';
    if (item['BootstrapClass'] == undefined || item['BootstrapClass'] == '') {
      bootstrapClass = 'col-md-12 px-3';
    }
    else {
      bootstrapClass = item['BootstrapClass'];
    }

    var style = '';
    if (item['Style'] != undefined && item['Style'] != '') {
      style = item['Style'];
    }


    dynamicHTML += '<div class="' + bootstrapClass + '" style="' + style + '">';
    dynamicHTML += '<div><h5><u>' + item['Title'] + '</u></h3></div>';


    dynamicHTML += `
  <table class="custom-table">

          <tr class="custom-tr">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
`;

    return dynamicHTML;

  }

  //-------------------------------------------------------------------

  startBuilderType_HTMLElement(item) {

    var dynamicHTML = '';

    //check bootstrap class
    var bootstrapClass = '';
    if (item['BootstrapClass'] == undefined || item['BootstrapClass'] == '') {
      bootstrapClass = 'col-md-12 px-3';
    }
    else {
      bootstrapClass = item['BootstrapClass'];
    }

    var style = '';
    if (item['Style'] != undefined && item['Style'] != '') {
      style = item['Style'];
    }


    dynamicHTML += '<' + item['Title'] + ' class="' + bootstrapClass + '" style="' + style + '">';

    return dynamicHTML;

  }

  //ROW ----------------------------------------------------------------------------
  rowBuilderForm(item) {

    var rowHTML = '';

    if (item['Type'] == 'Section' || item['Type'] == 'SectionSplit4') {
      rowHTML = this.rowBuilderType_Section(item);
    }
    else if (item['Type'] == 'HTMLText') {
      rowHTML = this.rowBuilderType_HTMLText(item);
    }
    else if (item['Type'] == 'HTMLTextDouble') {
      rowHTML = this.rowBuilderType_HTMLTextDouble(item);
    }

    return rowHTML;

  }

  rowBuilderType_Section(item) {

    var dynamicHTML = '';

    if (item['Display'] == 'ALL' || item['Display'] == 'ACCEPTANCE') {

      //console.log('MODIFY HERE');
      dynamicHTML += `
          <tr class="custom-tr" style="font-size:12px;">
            <td class="custom-td"><b>` + item['ColumnName'] + `</b></td>
            <td class="custom-td justify-content-md-center">` + item['Data'] + `</td>
        
`;


      if (item['Data2'] != undefined) {
        dynamicHTML += '<td class="custom-td justify-content-md-center">' + item['Data2'] + '</td>';
      }

      if (item['Data3'] != undefined) {
        dynamicHTML += '<td class="custom-td justify-content-md-center">' + item['Data3'] + '</td>';
      }

      if (item['Data4'] != undefined) {
        dynamicHTML += '<td class="custom-td justify-content-md-center">' + item['Data4'] + '</td>';
      }


      dynamicHTML += '</tr>';
    }



    return dynamicHTML;
  }

  //
  rowBuilderType_HTMLText(item) {

    var dynamicHTML = '';

    if (item['Display'] == 'ALL' || item['Display'] == 'ACCEPTANCE') {

      dynamicHTML += item['Data'];

    }



    return dynamicHTML;
  }

  //
  rowBuilderType_HTMLTextDouble(item) {

    var dynamicHTML = '';

    if (item['Display'] == 'ALL' || item['Display'] == 'ACCEPTANCE') {

      dynamicHTML += '<div class="row"><div class="col-md-6 px-3">' + item['Data'] + '</div><div class="col-md-6 px-3"">' + item['Data2'] + '</div></div>';

    }



    return dynamicHTML;
  }

  //END ----------------------------------------------------------------------------
  endBuilderForm(item) {

    var endHTML = '';

    if (item['Type'] == 'Section') {
      endHTML = this.endBuilderType_Section(item);
    }
    else if (item['Type'] == 'HTMLText') {
      endHTML = this.endBuilderType_Section(item);
    }
    else if (item['Type'] == 'HTMLElement') {
      endHTML = this.endBuilderType_HTMLElement(item);
    }

    return endHTML;
  }

  endBuilderType_Section(item) {

    var dynamicHTML = '';

    dynamicHTML += '</table>';
    dynamicHTML += '</div>';

    return dynamicHTML;
  }

  endBuilderType_HTMLElement(item) {

    var dynamicHTML = '';

    dynamicHTML += '</' + item['Title'] + '>';

    return dynamicHTML;
  }

  getCoverNotesApprovals(runCode: string, coverNoteApprovalId:number) {
    return this.authHttp.get<any>(this._Url + "GetCoverNotesApprovals/" + runCode + "/" + coverNoteApprovalId);
  }
  updateCoverNoteApproval(runCode: string, approvalID: number, loggedInUserID: number, comment: object) {
    return this.authHttp.post<any>(this._Url + "UpdateCoverNoteApproval/" + runCode + "/" + approvalID+"/"+loggedInUserID,comment);
  }

  updateCoverNoteNumber(productFormID: number) {
    return this.authHttp.get<any>(this._Url + "UpdateCoverNoteNumber/" + productFormID);

  }
}










