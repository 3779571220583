import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class GenericService {
  private _Url: string = "/api/generic/";


  private translationCache: { [lang: string]: any } = {};
  constructor(private authHttp: HttpClient, private auth: AuthenticationService, public translate: TranslateService) {

    //load translation file to local cache in order to be faster
    this.getTranslation('el').toPromise().then(translations => {
      return translations;
    }).catch(() => {
      return '';
    });

    this.getTranslation('en').toPromise().then(translations => {
      return translations;
    }).catch(() => {
      return '';
    });


  }

  getLanguageInWord(lang) {

    if (lang == "el") {
      return "Greek";
    }
    else if (lang == "en") {
      return "English";
    }
    else {
      return "(Error Getting Language)";
    }

  }
  getLanguage() {
    return localStorage.getItem('localLanguage');
  }
  translateData(data) {

    if (data == undefined || data == null)
      data = 'undefined';

    if (data == '') {
      return '';
    }
    else {
      return this.translate.instant(data);
    }

  }


  //getTranslation(lang: string): Observable<any> {
  //  return this.authHttp.get(`/assets/i18n/${lang}.json`);
  //}

  getTranslation(lang: string): Observable<any> {

    lang = (lang == undefined) ? 'el' : lang;

    if (this.translationCache[lang]) {
      // If the translations are already in the cache, return them as an Observable
      return new Observable(observer => {
        observer.next(this.translationCache[lang]);
        observer.complete();
      });
    } else {
      // Fetch the translations from the server and cache them
      return this.authHttp.get(`/assets/i18n/${lang}.json`).pipe(
        map(translations => {
          this.translationCache[lang] = translations;
          return translations;
        })
      );
    }
  }

  loadSpecificTranslation(key: string, lang: string): Promise<string> {
    return this.getTranslation(lang).toPromise().then(translations => {
      return translations && translations[key] ? translations[key] : '';
    }).catch(() => {
      return '';
    });
  }

  getTranslatedString(key: string, lang: string): Promise<string> {
    return this.loadSpecificTranslation(key, lang);
  }

  async useTranslation(key, lang) {
    var translatedValue = await this.loadSpecificTranslation(key, lang);

    if (translatedValue == undefined || translatedValue == '' || translatedValue == null) {
      translatedValue = key;
    }

    /*  console.log('Translated Value:', translatedValue);*/
    return translatedValue;
    // Use the translated value here
  }




  checkIfFormNotValid(templateForm) {

    var returnData;

    if (templateForm.invalid) {
      var count = 0;
      var message = '';
      var consoleOutput = '';
      //check if there is a form object (usually when going back)
      for (let key in templateForm.controls) {

        if (templateForm.controls[key].valid == false && templateForm.controls[key].disabled == false) {
          count += 1;
          consoleOutput += key + ', ';

        }

      }

      console.log(consoleOutput);

      message += this.translateData('Please complete all the form requirements!') + '<br>';
      message += this.translateData('The required fields are highlighted with red color!');

      returnData = {
        msg: message,
        isValid: false
      }

      //this.notificationService.show(ToasterType.warning, message, ToasterPosition.topCenter, 4000);

    }
    else {
      returnData = {
        msg: 'VALID',
        isValid: true
      }

    }

    return returnData;
  }

  markFormAsTouched(templateForm) {
    if (templateForm.invalid) {

      for (let key in templateForm.controls) {

        templateForm.controls[key].markAsTouched();

      }

    }
  }

  //Calculates the age between two dates
  ageBetweenDates(maximumDate: Date, minimumDate: Date): { years: number, months: number, days: number } {
    let years = maximumDate.getFullYear() - minimumDate.getFullYear();
    let months = maximumDate.getMonth() - minimumDate.getMonth();
    let days = maximumDate.getDate() - minimumDate.getDate();

    if (days < 0) {
      months -= 1;
      days += new Date(maximumDate.getFullYear(), maximumDate.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months, days };
  }
  // VALIDATIONS

  //addEventControlValidation(controlToCheck, controlValidation, valueCheck, validators) {

  //  //-------  initialize
  //  if (controlToCheck.value == valueCheck) {
  //    controlValidation.setValidators(validators);
  //  }
  //  else {
  //    controlValidation.clearValidators();
  //  }
  //  controlValidation.updateValueAndValidity();

  //  //-------  add event to the control
  //  controlToCheck.valueChanges.subscribe(val => {

  //    if (controlToCheck.value == valueCheck) {
  //      controlValidation.setValidators(validators);
  //    }
  //    else {
  //      controlValidation.clearValidators();
  //    }
  //    controlValidation.updateValueAndValidity();

  //  });

  //}

  addEventControlValidation(controlToCheck, controlsToValide, valueCheck, validators) {
    var notEqual = false;
    //to support not equals
    if (valueCheck[0] == '!') {
      notEqual = true;
      valueCheck = valueCheck.split('!')[1];
    }

    //-------  initialize
    if (!notEqual) {
      if (controlToCheck.value == valueCheck) {
        controlsToValide.forEach(control => {
          control.setValidators(validators);
        });

      }
      else {
        controlsToValide.forEach(control => {
          control.clearValidators();
        });
      }


  
    }else {
      if (controlToCheck.value != valueCheck) {
        controlsToValide.forEach(control => {
          control.setValidators(validators);
        });
    
      }
      else {
        controlsToValide.forEach(control => {
          control.clearValidators();
        });
      }
    }
    controlsToValide.forEach(control => {
      control.updateValueAndValidity();
    });
    //-------  add event to the control
    controlToCheck.valueChanges.subscribe(val => {

      if (!notEqual) {
        if (controlToCheck.value == valueCheck) {
          controlsToValide.forEach(control => {
            control.setValidators(validators);
          });

        }
        else {
          controlsToValide.forEach(control => {
            control.clearValidators();
          });
        }


     } else {
        if (controlToCheck.value != valueCheck) {
          controlsToValide.forEach(control => {
            control.setValidators(validators);
          });
     
        }
        else {
          controlsToValide.forEach(control => {
            control.clearValidators();
          });
        }
      }

      controlsToValide.forEach(control => {
        control.updateValueAndValidity();
      });


    });

  }

  scrollToFirstInvalidControl() {
    let form = document.getElementById('formId'); // <-- your formID
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    console.log(form.getElementsByClassName('ng-invalid')[0]);
    if (form == undefined) {
      console.log("Missing ID on the form template. Please put the formId ID!")
      return;
    }

    if (firstInvalidControl == undefined) {
      console.error("Could not find firstInvalidControl!")
      return;
    }


    let dims = firstInvalidControl.getBoundingClientRect();
    console.log('First Invalid Control: ', firstInvalidControl);
    //window.scrollTo(window.scrollX, dims.top - 20);
    window.scrollTo({ left: window.scrollX, top: dims.top - 100 + window.scrollY, behavior: 'smooth' });
    //firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }

  getAll(aEntityType: string) {
    console.log(aEntityType);
    return this.authHttp.get<any>(this._Url + aEntityType + "/ALL" + "/");
  }

  getAllActive(aEntityType: string) {
    return this.authHttp.get<any>(this._Url + aEntityType + "/ACT" + "/");
  }

  getByID(aEntityType: string, aEntityID: number) {
    return this.authHttp.get<any>(this._Url + aEntityType + "/ID" + "/" + aEntityID + "/");
  }


  getByName(aEntityType: string, aEntityName: string) {
    return this.authHttp.get<any>(this._Url + 'GetByName/' + aEntityType + "/NM" + "/" + aEntityName + "/");
  }

  add(aEntityType: string, aEntityObject: Object) {
    return this.authHttp.post<any>(this._Url + "Modify/" + aEntityType + "/I" + "/", aEntityObject);
  }

  update(aEntityType: string, aEntityID: number, aEntityObject: Object) {
    return this.authHttp.post<any>(this._Url + "Modify/" + aEntityType + "/U" + "/" + aEntityID + "/", aEntityObject);
  }

  delete(aEntityType: string, aEntityID: number, aEntityObject: Object) {
    //let aEntityObject: Object = new Object();
    //console.log(aEntityObject);
    return this.authHttp.post<any>(this._Url + "Modify/" + aEntityType + "/D" + "/" + aEntityID, aEntityObject)
  }

  convertHTMLtoBase64(aEntityObject: Object) {
    return this.authHttp.post<any>(this._Url + "ConvertHTMLtoBase64/", aEntityObject);
  }


  insertUpdate(aEntityType: string, aActionCode: string, aEntityID: number, aEntityObject: Object = null) {

    if (aActionCode == 'I') {
      return this.authHttp.post<any>(this._Url + "Modify/" + aEntityType + "/I", aEntityObject);
    }
    else if (aActionCode == 'U') {
      return this.authHttp.post<any>(this._Url + "Modify/" + aEntityType + "/U" + "/" + aEntityID, aEntityObject);
    }

  }

  getCurrentDate() {
    return this.authHttp.get<any>(this._Url + 'GetCurrentDate/');
  }

  removeTimeFromDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }
}
