var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export class GenericService {
    constructor(authHttp, auth, translate) {
        this.authHttp = authHttp;
        this.auth = auth;
        this.translate = translate;
        this._Url = "/api/generic/";
        this.translationCache = {};
        //load translation file to local cache in order to be faster
        this.getTranslation('el').toPromise().then(translations => {
            return translations;
        }).catch(() => {
            return '';
        });
        this.getTranslation('en').toPromise().then(translations => {
            return translations;
        }).catch(() => {
            return '';
        });
    }
    getLanguageInWord(lang) {
        if (lang == "el") {
            return "Greek";
        }
        else if (lang == "en") {
            return "English";
        }
        else {
            return "(Error Getting Language)";
        }
    }
    getLanguage() {
        return localStorage.getItem('localLanguage');
    }
    translateData(data) {
        if (data == undefined || data == null)
            data = 'undefined';
        if (data == '') {
            return '';
        }
        else {
            return this.translate.instant(data);
        }
    }
    //getTranslation(lang: string): Observable<any> {
    //  return this.authHttp.get(`/assets/i18n/${lang}.json`);
    //}
    getTranslation(lang) {
        lang = (lang == undefined) ? 'el' : lang;
        if (this.translationCache[lang]) {
            // If the translations are already in the cache, return them as an Observable
            return new Observable(observer => {
                observer.next(this.translationCache[lang]);
                observer.complete();
            });
        }
        else {
            // Fetch the translations from the server and cache them
            return this.authHttp.get(`/assets/i18n/${lang}.json`).pipe(map(translations => {
                this.translationCache[lang] = translations;
                return translations;
            }));
        }
    }
    loadSpecificTranslation(key, lang) {
        return this.getTranslation(lang).toPromise().then(translations => {
            return translations && translations[key] ? translations[key] : '';
        }).catch(() => {
            return '';
        });
    }
    getTranslatedString(key, lang) {
        return this.loadSpecificTranslation(key, lang);
    }
    useTranslation(key, lang) {
        return __awaiter(this, void 0, void 0, function* () {
            var translatedValue = yield this.loadSpecificTranslation(key, lang);
            if (translatedValue == undefined || translatedValue == '' || translatedValue == null) {
                translatedValue = key;
            }
            /*  console.log('Translated Value:', translatedValue);*/
            return translatedValue;
            // Use the translated value here
        });
    }
    checkIfFormNotValid(templateForm) {
        var returnData;
        if (templateForm.invalid) {
            var count = 0;
            var message = '';
            var consoleOutput = '';
            //check if there is a form object (usually when going back)
            for (let key in templateForm.controls) {
                if (templateForm.controls[key].valid == false && templateForm.controls[key].disabled == false) {
                    count += 1;
                    consoleOutput += key + ', ';
                }
            }
            console.log(consoleOutput);
            message += this.translateData('Please complete all the form requirements!') + '<br>';
            message += this.translateData('The required fields are highlighted with red color!');
            returnData = {
                msg: message,
                isValid: false
            };
            //this.notificationService.show(ToasterType.warning, message, ToasterPosition.topCenter, 4000);
        }
        else {
            returnData = {
                msg: 'VALID',
                isValid: true
            };
        }
        return returnData;
    }
    markFormAsTouched(templateForm) {
        if (templateForm.invalid) {
            for (let key in templateForm.controls) {
                templateForm.controls[key].markAsTouched();
            }
        }
    }
    //Calculates the age between two dates
    ageBetweenDates(maximumDate, minimumDate) {
        let years = maximumDate.getFullYear() - minimumDate.getFullYear();
        let months = maximumDate.getMonth() - minimumDate.getMonth();
        let days = maximumDate.getDate() - minimumDate.getDate();
        if (days < 0) {
            months -= 1;
            days += new Date(maximumDate.getFullYear(), maximumDate.getMonth(), 0).getDate();
        }
        if (months < 0) {
            years -= 1;
            months += 12;
        }
        return { years, months, days };
    }
    // VALIDATIONS
    //addEventControlValidation(controlToCheck, controlValidation, valueCheck, validators) {
    //  //-------  initialize
    //  if (controlToCheck.value == valueCheck) {
    //    controlValidation.setValidators(validators);
    //  }
    //  else {
    //    controlValidation.clearValidators();
    //  }
    //  controlValidation.updateValueAndValidity();
    //  //-------  add event to the control
    //  controlToCheck.valueChanges.subscribe(val => {
    //    if (controlToCheck.value == valueCheck) {
    //      controlValidation.setValidators(validators);
    //    }
    //    else {
    //      controlValidation.clearValidators();
    //    }
    //    controlValidation.updateValueAndValidity();
    //  });
    //}
    addEventControlValidation(controlToCheck, controlsToValide, valueCheck, validators) {
        var notEqual = false;
        //to support not equals
        if (valueCheck[0] == '!') {
            notEqual = true;
            valueCheck = valueCheck.split('!')[1];
        }
        //-------  initialize
        if (!notEqual) {
            if (controlToCheck.value == valueCheck) {
                controlsToValide.forEach(control => {
                    control.setValidators(validators);
                });
            }
            else {
                controlsToValide.forEach(control => {
                    control.clearValidators();
                });
            }
        }
        else {
            if (controlToCheck.value != valueCheck) {
                controlsToValide.forEach(control => {
                    control.setValidators(validators);
                });
            }
            else {
                controlsToValide.forEach(control => {
                    control.clearValidators();
                });
            }
        }
        controlsToValide.forEach(control => {
            control.updateValueAndValidity();
        });
        //-------  add event to the control
        controlToCheck.valueChanges.subscribe(val => {
            if (!notEqual) {
                if (controlToCheck.value == valueCheck) {
                    controlsToValide.forEach(control => {
                        control.setValidators(validators);
                    });
                }
                else {
                    controlsToValide.forEach(control => {
                        control.clearValidators();
                    });
                }
            }
            else {
                if (controlToCheck.value != valueCheck) {
                    controlsToValide.forEach(control => {
                        control.setValidators(validators);
                    });
                }
                else {
                    controlsToValide.forEach(control => {
                        control.clearValidators();
                    });
                }
            }
            controlsToValide.forEach(control => {
                control.updateValueAndValidity();
            });
        });
    }
    scrollToFirstInvalidControl() {
        let form = document.getElementById('formId'); // <-- your formID
        let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
        console.log(form.getElementsByClassName('ng-invalid')[0]);
        if (form == undefined) {
            console.log("Missing ID on the form template. Please put the formId ID!");
            return;
        }
        if (firstInvalidControl == undefined) {
            console.error("Could not find firstInvalidControl!");
            return;
        }
        let dims = firstInvalidControl.getBoundingClientRect();
        console.log('First Invalid Control: ', firstInvalidControl);
        //window.scrollTo(window.scrollX, dims.top - 20);
        window.scrollTo({ left: window.scrollX, top: dims.top - 100 + window.scrollY, behavior: 'smooth' });
        //firstInvalidControl.scrollIntoView();
        firstInvalidControl.focus();
    }
    getAll(aEntityType) {
        console.log(aEntityType);
        return this.authHttp.get(this._Url + aEntityType + "/ALL" + "/");
    }
    getAllActive(aEntityType) {
        return this.authHttp.get(this._Url + aEntityType + "/ACT" + "/");
    }
    getByID(aEntityType, aEntityID) {
        return this.authHttp.get(this._Url + aEntityType + "/ID" + "/" + aEntityID + "/");
    }
    getByName(aEntityType, aEntityName) {
        return this.authHttp.get(this._Url + 'GetByName/' + aEntityType + "/NM" + "/" + aEntityName + "/");
    }
    add(aEntityType, aEntityObject) {
        return this.authHttp.post(this._Url + "Modify/" + aEntityType + "/I" + "/", aEntityObject);
    }
    update(aEntityType, aEntityID, aEntityObject) {
        return this.authHttp.post(this._Url + "Modify/" + aEntityType + "/U" + "/" + aEntityID + "/", aEntityObject);
    }
    delete(aEntityType, aEntityID, aEntityObject) {
        //let aEntityObject: Object = new Object();
        //console.log(aEntityObject);
        return this.authHttp.post(this._Url + "Modify/" + aEntityType + "/D" + "/" + aEntityID, aEntityObject);
    }
    convertHTMLtoBase64(aEntityObject) {
        return this.authHttp.post(this._Url + "ConvertHTMLtoBase64/", aEntityObject);
    }
    insertUpdate(aEntityType, aActionCode, aEntityID, aEntityObject = null) {
        if (aActionCode == 'I') {
            return this.authHttp.post(this._Url + "Modify/" + aEntityType + "/I", aEntityObject);
        }
        else if (aActionCode == 'U') {
            return this.authHttp.post(this._Url + "Modify/" + aEntityType + "/U" + "/" + aEntityID, aEntityObject);
        }
    }
    getCurrentDate() {
        return this.authHttp.get(this._Url + 'GetCurrentDate/');
    }
    removeTimeFromDate(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
}
